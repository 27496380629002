import React from 'react';
import { FaHospitalUser, FaCalendarAlt, FaHospital } from "react-icons/fa";
import { Card, Row, Col, Typography, Statistic } from 'antd';

const { Title, Text } = Typography;

const DoctorDashCard = () => {
    const cardData = [
        {
            icon: <FaHospital style={{ fontSize: '2rem', color: '#1890ff' }} />,
            title: 'Total Patient',
            amount: 1500,
            date: "10 Jan 2024",
            color: '#e6f7ff'
        },
        {
            icon: <FaHospitalUser style={{ fontSize: '2rem', color: '#52c41a' }} />,
            title: 'Today Patient',
            amount: 1500,
            date: "10 Jan 2024",
            color: '#f6ffed'
        },
        {
            icon: <FaCalendarAlt style={{ fontSize: '2rem', color: '#faad14' }} />,
            title: 'Appointments',
            amount: 85,
            date: "10 Jan 2024",
            color: '#fff7e6'
        }
    ];

    return (
        <Row gutter={[16, 16]} className="mb-4">
            {cardData.map((item, index) => (
                <Col xs={24} sm={12} lg={8} key={index}>
                    <Card 
                        hoverable 
                        style={{ backgroundColor: item.color }}
                        bodyStyle={{ padding: '20px' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '20px' }}>
                                {item.icon}
                            </div>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>{item.title}</Title>
                                <Statistic value={item.amount} valueStyle={{ fontWeight: 'bold' }} />
                                <Text type="secondary">{item.date}</Text>
                            </div>
                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}

export default DoctorDashCard;