import React, { useEffect, useState } from 'react';
import Blog from '../Blog/Blog';
import Footer from '../../Shared/Footer/Footer';
import Testimonial from '../Testimonial/Testimonial';
import ClinicAndSpecialities from '../ClinicAndSpecialities/ClinicAndSpecialities';
import BookDoctor from '../BookOurDoctor/BookDoctor';
import Availabe from '../AvailableFeatures/Available';
import HeroSection from '../HeroSection/HeroSection';
import InfoPage from '../InfoPage/InfoPage';
import Header from '../../Shared/Header/Header';
import Service from '../Services/Service';
import Gallery from '../Gallery/Gallery';
import OurDoctors from '../OurDoctor/OurDoctors';
import useSubdomain from '../../../helpers/subdomain/subdomain';
import { getUserInfo } from '../../../service/auth.service';
import { Navigate, useNavigate } from 'react-router-dom';

const Home = () => {
    const { hospitalId, loading, error, subdomain } = useSubdomain();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const userInfo = await getUserInfo();
            console.log(userInfo);
            setIsAuthenticated(userInfo);
        };
        checkAuth();
    }, []);

    useEffect(() => {
        if(!loading){
      if(hospitalId){;
        console.log(isAuthenticated);
        if(isAuthenticated != null){
            if(isAuthenticated.role === "patient"){
                navigate(`/dashboard/${hospitalId}`);
            }
            else if(isAuthenticated.role === "doctor" ){
                navigate(`/doctor/dashboard/${hospitalId}`);
            }
            else if(isAuthenticated.role === "admin"){
                navigate(`/admin/dashboard`);
            }
        }
        else{
            navigate(`/hospital/${hospitalId}`);
        }
      }
      else{
        navigate("/error");
      }   
    }

    }, [hospitalId, loading, isAuthenticated]);



    if (loading) return <div className='subdomain-fetching'>Fetching {subdomain} Hospital Details...</div>;
    if (error) return <p>Error 404</p>;

    return (
        <>
            <Header />
            <HeroSection />
            <InfoPage />
            <Service />
            <ClinicAndSpecialities />
            <BookDoctor />
            <Blog />
            <Availabe />
            <OurDoctors />
            <Testimonial />
            <Gallery />
            <Footer />
        </>
    );
};

export default Home;