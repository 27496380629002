import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DoctorDashCard from "./doctor/DoctorDashCard";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import DashboardPage from "./doctor/DashboardPage";
import PatientDashboard from "./PatientDashboard";

const Dashboard = () => {
  const { role, isLoading } = useAuthCheck();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // useEffect(() => {
  //   if (!isLoading) {
  //     if (!role) {
  //       // User is not authenticated, redirect to login
  //       navigate(`/hospital/${id}`);
  //     } else {
  //       setIsAuthorized(true);
  //     }
  //   }
  // }, [isLoading, role, navigate, id]);

  // if (isLoading || !isAuthorized) {
  //   return <div>Loading...</div>; // Or a more sophisticated loading component
  // }

  return (
    <DashboardLayout>
      {role === "doctor" && <DoctorDashCard />}
      <div className="row">
        {role === "patient" && (
          <div
            className="col-md-12 rounded"
            style={{ background: "#f8f9fa" }}
          >
            <h5 className="text-title my-3">My Appointments </h5>
            <PatientDashboard />
          </div>
        )}
        {role === "doctor" && (
          <div
            className="col-md-12 rounded"
            style={{ background: "#f8f9fa" }}
          >
            <h5 className="text-title py-3">Appointments</h5>
            <DashboardPage />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;