import { jwtDecode } from "jwt-decode";

export const decodeToken = (token) => {
  try {
    const data = jwtDecode(token);
    return data;
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};