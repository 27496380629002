import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "../../service/auth.service";
import { useEffect } from "react";

const PrivateOutletPatient = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const localAuth = getUserInfo();
    console.log("localAuth" , localAuth);
    
    if (!localAuth) {
      navigate(`hospital/${id}`, { replace: true });
    }
    if (localAuth && localAuth.role !== 'patient' ) {
      navigate(`hospital/${id}`, { replace: true });
    }
  }, [navigate]);

  return <Outlet />;
};

export default PrivateOutletPatient;
