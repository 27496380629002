import { useState, useEffect } from 'react';

export default function useSubdomain() {
  const [hospitalId, setHospitalId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subdomain, setSubDomain] = useState(null);
  useEffect(() => {
    async function fetchHospitalId() {
      try {
        // const sd = window.location.hostname.split('.')[0];
        const sd = "apollo";
        setSubDomain(sd);
        const response = await fetch(`https://testdeploy-production-f425.up.railway.app/api/v1//hospital/getHospitalId/${sd}`);
        const res = await response.json();
        setHospitalId(res.data.id);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchHospitalId();
  }, []);

  return { hospitalId, loading, error, subdomain };
}