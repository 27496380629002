import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useNavigate } from "react-router-dom";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetAllPaymentsQuery } from "../../../redux/api/paymentApi";

const Payments = () => {
  const [payments, setPayments] = useState([]);

  const navigate = useNavigate();
  const userId = getUserIdFromToken();

  console.log("User ID:", userId);

  // Fetch the hospital ID using the user ID
  const {
    data: hospitalData,
    isLoading: hospitalLoading,
    error: hospitalError,
  } = useGetHospitalIdFromUserIdQuery(userId);

  // Fetch payments using the hospital ID
  const hospitalId = hospitalData?.id?.toString();
  console.log("hospitalData", hospitalId, hospitalData);

  const {
    data: paymentsData,
    isLoading: paymentsLoading,
    error: paymentsError,
  } = useGetAllPaymentsQuery(hospitalId);
  console.log("paymentsData", paymentsData);

  useEffect(() => {
    if (paymentsData && paymentsData.payments) {
      setPayments(paymentsData.payments);
    }

    if (!hospitalId) {
      navigate("/admin/dashboard");
    }

    if (paymentsError) {
      console.error("Error fetching payments:", paymentsError);
    }
  }, [hospitalData, paymentsData, hospitalId, paymentsError, navigate]);

  return (
    <>
      <AdminLayout>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="datatable table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Payment ID</th>
                        <th>Patient Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.map((payment) => (
                        <tr key={payment.id}>
                          <td>{payment.id}</td>
                          <td>{payment.patientName}</td>
                          <td>{payment.amount}</td>
                          <td>{new Date(payment.date).toLocaleDateString()}</td>
                          <td>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id={`status_${payment.id}`}
                                className="check"
                                checked={payment.status === "completed"}
                              />
                              <label
                                htmlFor={`status_${payment.id}`}
                                className="checktoggle"
                              ></label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Payments;
