import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "../../service/auth.service";
import { useEffect } from "react";

const PrivateOutletDoctor = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const localAuth = getUserInfo();
    console.log("localAuth", localAuth);

    if (!localAuth) {
      navigate(`hospital/${id}`, { replace: true });
    }
    if (localAuth && localAuth.role !== "doctor") {
      console.log("hitting");

      navigate(`hospital/${id}`, { replace: true });
    }
  }, [navigate]);

  return <Outlet />;
};

export default PrivateOutletDoctor;
