import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "../../service/auth.service";
import { useEffect } from "react";

const PrivateOutletAdmin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const localAuth = getUserInfo();
    console.log("localAuth" , localAuth);
    
    if (!localAuth) {
      navigate(`/admin/login`, { replace: true });
    }
    if (localAuth && localAuth.role !== 'admin' ) {
      navigate(`/admin/login`, { replace: true });
    }
  }, [navigate]);

  return <Outlet />;
};

export default PrivateOutletAdmin;
