import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./Patients.css";
import { useNavigate } from "react-router-dom";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetAllPatientsQuery } from "../../../redux/api/patientApi";

const Patients = () => {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const userId = getUserIdFromToken();

  // Fetch the hospital ID using the user ID
  const {
    data: hospitalData,
    isLoading: hospitalLoading,
    error: hospitalError,
  } = useGetHospitalIdFromUserIdQuery(userId);
  console.log("userId", userId);

  // Fetch patients using the hospital ID
  const hospitalId = "b06eefb6-f6ee-4235-8825-9fb8f676f9b3"//hospitalData?.id;
  console.log("hospitalId", hospitalId);

  const {
    data: patientsData,
    isLoading: patientsLoading,
    error: patientsError,
  } = useGetAllPatientsQuery(hospitalId);
  console.log("patientsData", patientsData);

  useEffect(() => {
    if (patientsData && patientsData.length > 0) {
      setPatients(patientsData);
    }

    if (!hospitalId) {
      navigate("/admin/dashboard");
    }

    if (patientsError) {
      console.error("Error fetching patients:", patientsError);
    }
  }, [hospitalData, patientsData, hospitalId, patientsError, navigate]);

  return (
    <>
      <AdminLayout>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="datatable table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Disease</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patients.map((patient) => (
                        <tr key={patient.id}>
                          <td>
                            <h2 className="table-avatar">
                              <a
                                href={`/patient/${patient.id}`}
                                className="avatar avatar-sm mr-2"
                              >
                                <img
                                  className="avatar-img rounded-circle"
                                  src={patient.image}
                                  alt={patient.name}
                                />
                              </a>
                              <a href={`/patient/${patient.id}`}>
                                Dr. {patient.name}
                              </a>
                            </h2>
                          </td>
                          <td>{patient.disease}</td>
                          <td>{patient.email}</td>
                          <td>{patient.phone}</td>
                          <td>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id={`status_${patient.id}`}
                                className="check"
                                checked={patient.status === "active"}
                              />
                              <label
                                htmlFor={`status_${patient.id}`}
                                className="checktoggle"
                              ></label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};
export default Patients;
