import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { message, Spin } from "antd"; // Ant Design for notification and spinner
import { useVerifyUserMutation } from "../../redux/api/authApi"; // Assuming this API hook exists

const UserVerification = () => {
  const { userId, token } = useParams();
  const [verifyUser, { isLoading, isSuccess, isError, error }] =
    useVerifyUserMutation();
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    const verify = async () => {
      console.log("userId, token", userId, token);
      try {
        const data = {
          userId,
          token
        };
        const response = await verifyUser(data);
        console.log("response", response);
        if (response.data) {
            setVerificationStatus("success");
            message.success("Verification successful!");
        }
        else{
            setVerificationStatus("failed");
            message.error("Verification failed!");
        }
      } catch (err) {
        setVerificationStatus("failed");
        message.error(err?.data?.message || "Verification failed");
      }
    };

    verify();
  }, [userId, token, verifyUser]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {verificationStatus === "success" && (
        <h2>Your account has been verified successfully! Go back to the login page</h2>
      )}
      {verificationStatus === "failed" && (
        <h2>Verification failed. May be the session is expired</h2>
      )}
    </div>
  );
};

export default UserVerification;
