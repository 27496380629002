import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import log from "../../images/doc/info.svg";
import register from "../../images/doc/register.svg";
import "./SignInForm.css";
import HospitalSignUp from "./HospitalSignUp";
import HospitalSignIn from "./HospitalSignIn";
import useAuthCheck from "../../redux/hooks/useAuthCheck";

const HospitalSignInForm = () => {
  const { id } = useParams();
  const data = useAuthCheck();
  const navigate = useNavigate();
  console.log("useAuthCheck data" , data);
  const [isSignUp, setSignUp] = useState(false);
  useEffect(() => {
    if (data && data?.role === "patient") {
        console.log("inside id" , id);
        
       navigate(`/dashboard/${id}`);
    } else if (data && data?.role === "doctor") {
       navigate(`/doctor/dashboard/${id}`);
    }
  }, []);
  return (
    <div
      className={`${
        isSignUp
          ? "signin-signup-container sign-up-mode"
          : "signin-signup-container"
      }`}
    >
      <Link to="/">
        <span className="pageCloseBtn">
          <FaTimes />
        </span>
      </Link>
      <div className="forms-container">
        <div className="signIn-singUp">
          <HospitalSignIn />
          <HospitalSignUp setSignUp={setSignUp} />
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3 className="text-white">New here ?</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
              beatae quas magnam!
            </p>
            <button
              className="iBtn transparent"
              onClick={() => setSignUp(true)}
            >
              Sign Up
            </button>
          </div>
          <img src={`${log}`} alt="" className="pImg" />
        </div>

        <div className="panel right-panel">
          <div className="content">
            <h3 className="text-white">One of us ?</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
              beatae quas magnam!
            </p>
            <button
              className="iBtn transparent"
              onClick={() => setSignUp(false)}
            >
              Sign In
            </button>
          </div>
          <img src={`${register}`} alt="" className="pImg" />
        </div>
      </div>
    </div>
  );
};

export default HospitalSignInForm;
