import { baseApi } from "./baseApi";

const HOSPITALS_URL = "/hospital";

export const hospitalApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createHospital: build.mutation({
      query: (data) => ({
        url: `${HOSPITALS_URL}`,
        method: "POST",
        data: data,
      }),
    }),
    getAllHospitals: build.query({
      query: (arg) => ({
        url: `${HOSPITALS_URL}/`,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          hospitals: response.data,
          meta: response.meta,
        };
      },
    }),
    getSingleHospital: build.query({
      query: (id) => ({
        url: `${HOSPITALS_URL}/${id}`,
        method: "GET",
      }),
    }),
    getHospitalIdFromUserId: build.query({  
      query: (id) => ({
        url: `${HOSPITALS_URL}/getHospitalId/${id}`,
        method: 'GET',
      }),
    }),
    updateHospital: build.mutation({
      query: ({ data, id }) => ({
        url: `${HOSPITALS_URL}/${id}`,
        method: "PATCH",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    deleteHospital: build.mutation({
      query: (id) => ({
        url: `${HOSPITALS_URL}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateHospitalMutation,
  useDeleteHospitalMutation,
  useGetAllHospitalsQuery,
  useGetHospitalIdFromUserIdQuery,
  useUpdateHospitalMutation,
  useGetSingleHospitalQuery,
} = hospitalApi;
