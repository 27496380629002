import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaPhoneSlash } from 'react-icons/fa';
import "./video.css";
import { getUserInfo } from "../../service/auth.service";

export const LiveVideo = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const appId = process.env.REACT_APP_AGORA_APP_ID || "c87677edbccf4e18a51e2461f192559e";
  const { channelName } = useParams();
  const [activeConnection, setActiveConnection] = useState(true);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingData, setRecordingData] = useState(null);
  const { localMicrophoneTrack, isLoading: micLoading } = useLocalMicrophoneTrack();
  const { localCameraTrack, isLoading: cameraLoading } = useLocalCameraTrack();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const userInfo = await getUserInfo();
      console.log(userInfo);
      setIsAuthenticated(userInfo);
    };
    checkAuth();
  }, []);

  useJoin(
    {
      appid: appId,
      channel: channelName,
      token: null,
    },
    activeConnection,
  );

  usePublish([micOn ? localMicrophoneTrack : null, cameraOn ? localCameraTrack : null]);
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  useEffect(() => {
    audioTracks.forEach((track) => track.play());
  }, [audioTracks]);

  useEffect(() => {
    if (localMicrophoneTrack) {
      micOn ? localMicrophoneTrack.setEnabled(true) : localMicrophoneTrack.setEnabled(false);
    }
  }, [micOn, localMicrophoneTrack]);

  useEffect(() => {
    if (localCameraTrack) {
      cameraOn ? localCameraTrack.setEnabled(true) : localCameraTrack.setEnabled(false);
    }
  }, [cameraOn, localCameraTrack]);

  const startRecording = async () => {
    try {
      const acquireResponse = await axios.post(`${apiUrl}/call/acquire`, {
        channelName,
        uid: "69"
      });
      const resourceId = acquireResponse.data.resourceId;
      const startResponse = await axios.post(`${apiUrl}/call/start`, {
        channelName,
        uid: "69",
        resourceId,
        token: null,
      });
      const sid = startResponse.data.sid;
      setRecordingData({ resourceId, sid });
      setIsRecording(true);
    } catch (error) {
      console.error('Failed to start recording:', error);
    }
  };

  const stopRecording = async () => {
    try {
      if (!recordingData) return;
      await axios.post(`${apiUrl}/call/stop`, {http://localhost:3000/call/via/HAR202408225001
        channelName,
        uid: "69",
        resourceId: recordingData.resourceId,
        sid: recordingData.sid
      });
      setRecordingData(null);
      setIsRecording(false);
    } catch (error) {
      console.error('Failed to stop recording:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && isAuthenticated.role === "doctor" && remoteUsers.length > 0 && !isRecording) {
      startRecording();
    }
  }, [isAuthenticated, remoteUsers, isRecording]);

  useEffect(() => {
    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, [isRecording]);

  const toggleMic = () => {
    if (!micLoading) {
      setMic(prev => !prev);
    }
  };

  const toggleCamera = () => {
    if (!cameraLoading) {
      setCamera(prev => !prev);
    }
  };

  const endCall = () => {
    if (isAuthenticated && isAuthenticated.role === "doctor") {
      stopRecording();
    }
    setActiveConnection(false);
    navigate('/doctor/dashboard/');
  };

  return (
    <div className="video-call-container">
      <div className="remote-video-grid">
        {remoteUsers.map((user) => (
          <div key={user.uid} className="remote-video-container">
            <RemoteUser user={user} />
          </div>
        ))}
      </div>
      <div className="local-video-container">
        <LocalUser
          audioTrack={micOn ? localMicrophoneTrack : null}
          videoTrack={cameraOn ? localCameraTrack : null}
          cameraOn={cameraOn}
          micOn={micOn}
        />
      </div>
      <div className="controls-toolbar">
        <button className="control-btn" onClick={toggleMic} disabled={micLoading}>
          {micOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
        </button>
        <button className="control-btn" onClick={toggleCamera} disabled={cameraLoading}>
          {cameraOn ? <FaVideo /> : <FaVideoSlash />}
        </button>
        <button className="control-btn end-call" onClick={endCall}>
          <FaPhoneSlash />
        </button>
      </div>
      {isRecording && <div className="recording-indicator">Recording in progress</div>}
    </div>
  );
};
