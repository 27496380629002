import React from "react";
import img from "../../images/avatar.jpg";
import "./DashboardSidebar.css";
import { Link, NavLink, useParams } from "react-router-dom";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import {
  FaTable,
  FaCalendarDay,
  FaUserInjured,
  FaHourglassStart,
  FaRegStar,
  FaUserCog,
  FaBlog,
  FaSignOutAlt,
  FaLock,
  FaHouseUser,
} from "react-icons/fa";
import { clearLocalStorage } from "../../utils/local-storage";

const DashboardSidebar = () => {
  const { id } = useParams();
  const { data, role } = useAuthCheck();
  console.log("id coming in sidebar", id);
  const handleLogout = () => {
    clearLocalStorage();
  };
  return (
    <div className="profile-sidebar p-4 rounded shadow-sm">
      <div className="p-3 text-center border-bottom mb-4">
        {role === "doctor" ? (
          <div className="profile-info">
            <Link to={"/"}>
              <img
                src={data?.img ? data?.img : img}
                alt=""
                className="rounded-circle mb-3"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
            </Link>
            <div className="profile-details">
              <h4 className="mb-2">{data?.firstName + " " + data?.lastName}</h4>
              <p className="text-muted mb-0">{data?.designation}</p>
            </div>
          </div>
        ) : (
          <div className="profile-info">
            <Link to={`/dashboard/${id}`}>
              <img
                src={data?.img ? data?.img : img}
                alt=""
                className="rounded-circle mb-3"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
            </Link>
            <div className="profile-details">
              <h4 className="mb-2">{data?.firstName + " " + data?.lastName}</h4>
              <p className="text-muted mb-0">{data?.email}</p>
            </div>
          </div>
        )}
      </div>
      <nav className="dashboard-menu">
        {role === "patient" ? (
          <ul className="list-unstyled">
            <li className="mb-3">
              <NavLink
                to={`/dashboard/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaTable className="icon me-2" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <li className="mb-3">
                                <NavLink to={'/dashboard/favourite'} activeClassName="active" className="btn btn-light w-100 text-start">
                                    <FaHouseUser className="icon me-2" />
                                    <span>Favourites</span>
                                </NavLink>
                            </li> */}
            <li className="mb-3">
              <NavLink
                to={`/dashboard/profile-setting/${id}`}
                activeClassName="active"
                className="btn btn-light w-100 text-start"
              >
                <FaUserCog className="icon me-2" />
                <span>Profile Settings</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/dashboard/change-password/${id}`}
                activeClassName="active"
                className="btn btn-light w-100 text-start"
              >
                <FaLock className="icon me-2" />
                <span>Change Password</span>
              </NavLink>
            </li>
            <li onClick={handleLogout}>
              <NavLink
                to={`/hospital/${id}`}
                className="btn btn-danger w-100 text-start"
              >
                <FaSignOutAlt className="icon me-2" />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul className="list-unstyled">
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaTable className="icon me-2" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/appointments/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaCalendarDay className="icon me-2" />
                <span>Appointments</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/my-patients/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaUserInjured className="icon me-2" />
                <span>My Patients</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/prescription/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaUserInjured className="icon me-2" />
                <span>Prescription</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/schedule/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaCalendarDay className="icon me-2" />
                <span>Schedule Timings</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/invoices/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaHourglassStart className="icon me-2" />
                <span>Invoices</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/reviews/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaRegStar className="icon me-2" />
                <span>Reviews</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/profile-setting/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaUserCog className="icon me-2" />
                <span>Profile Settings</span>
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/blogs/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaBlog className="icon me-2" />
                <span>Blogs (Will move to Admin)</span>
              </NavLink>
            </li>
            {/* <li className="mb-3">
              <NavLink
                to={`/doctor/dashboard/change-password/${id}`}
                activeClassName="active"
                end
                className="btn btn-light w-100 text-start"
              >
                <FaLock className="icon me-2" />
                <span>Change Password</span>
              </NavLink>
            </li> */}
            <li onClick={handleLogout}>
              <NavLink
                to={`/hospital/${id}`}
                className="btn btn-danger w-100 text-start"
              >
                <FaSignOutAlt className="icon me-2" />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default DashboardSidebar;
