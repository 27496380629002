import React, { useEffect, useState } from "react";
import { FaCheck, FaEnvelope, FaLock, FaTimes, FaUser } from "react-icons/fa";
import SocialSignUp from "./SocialSignUp";
import Spinner from "react-bootstrap/Spinner";
import swal from "sweetalert";
import { useAdminSignUpMutation } from "../../redux/api/authApi";
import { message } from "antd";

// password regex
// ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$
// At least one upper case English letter, (?=.*?[A-Z])
// At least one lower case English letter, (?=.*?[a-z])
// At least one digit, (?=.*?[0-9])
// At least one special character, (?=.*?[#?!@$%^&*-])
// Minimum eight in length .{8,} (with the anchors)
const SignUp = ({ setSignUp }) => {
  const [error, setError] = useState({});
  const [infoError, setInfoError] = useState("");
  const [loading, setLoading] = useState(false);
  const formField = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "admin",
  };
  const [user, setUser] = useState(formField);
  const [userType, setUserType] = useState("admin");
  const [
    adminSignUp,
    {
      data: aData,
      isSuccess: aIsSuccess,
      isError: aIsError,
      error: aError,
      isLoading: aIsLoading,
    },
  ] = useAdminSignUpMutation();
  const [passwordValidation, setPasswordValidation] = useState({
    carLength: false,
    specailChar: false,
    upperLowerCase: false,
    numeric: false,
  });

  const handleSignUpSuccess = () => {
    setLoading(false);
    setUser(formField);
  };

  useEffect(() => {
    if (aIsError && aError) {
      message.error("Email Already Exist !!");
      setLoading(false);
    }

    if (!aIsError && aIsSuccess) {
      handleSignUpSuccess();
      setLoading(false);
      swal({
        icon: "success",
        text: `Successfully Account Created Please Verify Your email`,
        timer: 5000,
      });
    }
  }, [aIsError, aError, aIsLoading, aData, setSignUp, setLoading, aIsSuccess]);

  const [emailError, setEmailError] = useState({
    emailError: false,
  });

  const handleEmailError = (name, value) => {
    if (name === "email") {
      setEmailError({
        emailError: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      });
    }
  };

  const hanldeValidation = (name, value) => {
    if (name === "password") {
      setPasswordValidation({
        carLength: value.length > 8,
        specailChar: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value),
        upperLowerCase: /^(?=.*[a-z])(?=.*[A-Z])/.test(value),
        numeric: /^(?=.*\d)/.test(value),
      });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value
    }));
    
    hanldeValidation(name, value);
    handleEmailError(name, value);
  
    // Check if the value is valid and update the state accordingly
    let isFieldValid = true;
  
    if (name === "email") {
      isFieldValid = /\S+@\S+\.\S+/.test(value);
    }
    if (name === "password") {
      isFieldValid =
        value.length > 8 &&
        /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value) &&
        /^(?=.*[a-z])(?=.*[A-Z])/.test(value) &&
        /^(?=.*\d)/.test(value);
    }
  
  };
  
  const handleUserTypeChange = (e) => {
    const newRole = e.target.value;
    console.log("Selected role:", newRole);
  
    setUserType(newRole);
    setUser((prevUser) => ({
      ...prevUser,
      role: newRole
    }));
  };
  

  const hanldeOnSubmit = async (e) => {
    e.preventDefault();
    
    // Explicitly set the role to 'admin'
    const updatedUser = {
      ...user,
      role: 'admin'
    };
  
    console.log("User before the request:", updatedUser);
  
    setLoading(true);
    console.log("Submitting admin sign up request...");
    adminSignUp(updatedUser);
  };
  
  return (
    <form className="sign-up-form" onSubmit={hanldeOnSubmit}>
      <h2 className="title">Sign Up</h2>
      <div className="input-field">
        <span className="fIcon">
          <FaUser />
        </span>
        <input
          placeholder="First Name"
          name="firstName"
          type="text"
          onChange={(e) => handleOnChange(e)}
          value={user.firstName}
        />
      </div>
      <div className="input-field">
        <span className="fIcon">
          <FaUser />
        </span>
        <input
          placeholder="Last Name"
          name="lastName"
          type="text"
          onChange={(e) => handleOnChange(e)}
          value={user.lastName}
        />
      </div>
      <div className="input-field">
        <span className="fIcon">
          <FaEnvelope />
        </span>
        <input
          placeholder="Email"
          name="email"
          type="email"
          onChange={(e) => handleOnChange(e)}
          value={user.email}
        />
      </div>
      <div className="input-field">
        <span className="fIcon">
          <FaLock />
        </span>
        <input
          type="password"
          name="password"
          placeholder="password"
          onChange={(e) => handleOnChange(e)}
          value={user.password}
        />
      </div>
      <div className="input-field d-flex align-items-center gap-2 justify-content-center">
        <div className="text-nowrap">I'M A</div>
        <select
          className="form-select w-50"
          aria-label="select"
          onChange={(e) => handleUserTypeChange(e)}
          defaultValue="admin"
        >
          {/* <option value="patient">Patient</option> */}
          <option value="admin">Admin</option>
        </select>
      </div>
      {error.length && <h6 className="text-danger text-center">{error}</h6>}
      {infoError && <h6 className="text-danger text-center">{infoError}</h6>}
      <button
        type="submit"
        className="btn btn-primary btn-block mt-2 iBtn"
        disabled={
          passwordValidation.carLength &&
          passwordValidation.numeric &&
          passwordValidation.upperLowerCase &&
          passwordValidation.specailChar &&
          emailError.emailError
            ? ""
            : true
        }
      >
        {loading ? <Spinner animation="border" variant="info" /> : "Sign Up"}
      </button>

      <div className="password-validatity mx-auto">
        <div
          style={emailError.emailError ? { color: "green" } : { color: "red" }}
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Must Have Valid Email.</span>
          </p>
        </div>

        <div
          style={
            passwordValidation.carLength ? { color: "green" } : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Password Must Have atlast 8 character.</span>
          </p>
        </div>

        <div
          style={
            passwordValidation.specailChar
              ? { color: "green" }
              : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Password Must Have a special cracter.</span>
          </p>
        </div>

        <div
          style={
            passwordValidation.upperLowerCase
              ? { color: "green" }
              : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">
              Password Must Have uppercase and lower case.
            </span>
          </p>
        </div>

        <div
          style={
            passwordValidation.numeric ? { color: "green" } : { color: "red" }
          }
        >
          <p>
            {passwordValidation.numeric ? <FaCheck /> : <FaTimes />}
            <span className="ms-2">Password Must Have Number.</span>
          </p>
        </div>
      </div>

      {/* <p className="social-text">Or Sign up with social account</p>
      <SocialSignUp /> */}
    </form>
  );
};

export default SignUp;
