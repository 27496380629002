import React, { useState, useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./Doctors.css";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import { getUserIdFromToken } from "../../../utils/local-storage";
import { useNavigate } from "react-router-dom";

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const navigate = useNavigate();
  const userId = getUserIdFromToken();

  console.log("User ID:", userId);

  // Fetch the hospital ID using the user ID
  const {
    data: hospitalData,
    isLoading: hospitalLoading,
    error: hospitalError,
  } = useGetHospitalIdFromUserIdQuery(userId);
  console.log("hospitalData", hospitalData?.id ,hospitalData);

  // Fetch doctors using the hospital ID
  const id = hospitalData?.id;
  const {
    data: doctorsData,
    isLoading: doctorsLoading,
    error: doctorsError,
  } = useGetDoctorsQuery({ id }, { skip: !id });
console.log("doctorsData" , doctorsData);

  useEffect(() => {
    if (doctorsData && doctorsData.doctors) {
      setDoctors(doctorsData.doctors);
    }

    // if (!id) {
    //   navigate("/admin/dashboard");
    // }

    if (doctorsError) {
      console.error("Error fetching doctors:", doctorsError);
    }
  }, [hospitalData, doctorsData, id, doctorsError, navigate]);

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="datatable table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Doctor Name</th>
                      <th>Speciality</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctors.map((doctor) => (
                      <tr key={doctor.id}>
                        <td>
                          <h2 className="table-avatar">
                            <a
                              href={`/doctor/${doctor.id}`}
                              className="avatar avatar-sm mr-2"
                            >
                              <img
                                className="avatar-img rounded-circle"
                                src={doctor.image}
                                alt={doctor.name}
                              />
                            </a>
                            <a href={`/doctor/${doctor.id}`}>
                              Dr. {doctor.name}
                            </a>
                          </h2>
                        </td>
                        <td>{doctor.services}</td>
                        <td>{doctor.email}</td>
                        <td>{doctor.phone}</td>
                        <td>
                          <div className="status-toggle">
                            <input
                              type="checkbox"
                              id={`status_${doctor.id}`}
                              className="check"
                              checked={doctor.status === "active"}
                            />
                            <label
                              htmlFor={`status_${doctor.id}`}
                              className="checktoggle"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Doctors;
