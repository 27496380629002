import { jwtDecode } from "jwt-decode";
export const setUserInfo = ({ accessToken }) => {
  return setLocalStorage("accessToken", accessToken);
};

export const setLocalStorage = (key, token) => {
  if (!key || typeof window === "undefined") {
    return "";
  }
  console.log("key", key, token);

  return localStorage.setItem(key, token);
};
export const clearLocalStorage = () => {
  // if (!key || typeof window === "undefined") {
  //   return "";
  // }
  // console.log("key", key, token);

  return localStorage.removeItem("accessToken");
};

export const getFromLocalStorage = (key) => {
  if (!key || typeof window === "undefined") {
    return "";
  }
  return localStorage.getItem(key);
};

export const getUserIdFromToken = () => {
  const token = getFromLocalStorage("accessToken");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userId; // Adjust based on the actual structure of your token
    } catch (error) {
      console.error("Error decoding token", error);
      return null;
    }
  }
  return null;
};
