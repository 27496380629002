import React from "react";
import img from "../../../images/doc/doctor 3.jpg";
import moment from "moment";
import {
  useGetPatientAppointmentsQuery,
  useGetPatientInvoicesQuery,
} from "../../../redux/api/appointmentApi";
import { useGetPatientPrescriptionQuery } from "../../../redux/api/prescriptionApi";
import { Button, Tabs, Tag, Tooltip, Card, Avatar } from "antd";
import CustomTable from "../../UI/component/CustomTable";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  FaRegEye,
  FaUserMd,
  FaCalendarAlt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { clickToCopyClipBoard } from "../../../utils/copyClipBoard";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { getUserInfo } from "../../../service/auth.service";
const { TabPane } = Tabs;

const PatientDashboard = () => {
  const { id } = useParams();
  const authdata = useAuthCheck();
  const userData = getUserInfo()
  console.log("userData ", userData);
  const patientId = userData?.userId;
  const hospitalId = id;
  const { data, isLoading: pIsLoading } = useGetPatientAppointmentsQuery({
    hospitalId,
    patientId,
  });
  const { data: prescriptionData, prescriptionIsLoading } =
    useGetPatientPrescriptionQuery(patientId);
  const { data: invoices, isLoading: InvoicesIsLoading } =
    useGetPatientInvoicesQuery({hospitalId,
        patientId});

  const InvoiceColumns = [
    // ... (keep the InvoiceColumns as in the previous response)
  ];

  const prescriptionColumns = [
    {
      title: "App Doctor",
      key: 11,
      width: 200,
      render: function (data) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={img} size={40} style={{ marginRight: 10 }} />
            <div>
              <div style={{ fontWeight: "bold" }}>
                {data?.doctor?.firstName + " " + data?.doctor?.lastName}
              </div>
              <div style={{ fontSize: "0.8rem", color: "#888" }}>
                {data?.doctor?.designation}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Appointment Id",
      dataIndex: "appointment",
      key: 1,
      render: ({ trackingId }) => (
        <Tooltip title="Copy Tracking Id">
          <Button onClick={() => clickToCopyClipBoard(trackingId)}>
            <Tag color="#87d068" className="text-uppercase">
              {trackingId}
            </Tag>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "Appointment Date",
      key: 12,
      width: 150,
      render: function (data) {
        return (
          <div>
            {moment(data?.appointment?.scheduleDate).format("MMM D, YYYY")}
            <div style={{ color: "#1890ff" }}>
              {data?.appointment?.scheduleTime}
            </div>
          </div>
        );
      },
    },
    {
      title: "Follow-Update",
      dataIndex: "followUpdate",
      key: 4,
      render: function (data) {
        return (
          <Tag color="#87d068">{dayjs(data).format("MMM D, YYYY hh:mm A")}</Tag>
        );
      },
    },
    {
      title: "Archived",
      dataIndex: "isArchived",
      key: 5,
      render: function ({ isArchived }) {
        return (
          <Tag color={isArchived ? "#f50" : "#108ee9"}>
            {isArchived ? "Yes" : "Under Treatment"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: 13,
      width: 100,
      render: function (data) {
        return (
          <Link to={`/dashboard/prescription/${data.id}`}>
            <Button type="primary" icon={<FaRegEye />}>
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  const appointmentColumns = [
    {
      title: "Doctor",
      key: 20,
      width: 200,
      render: function (data) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={img} size={40} style={{ marginRight: 10 }} />
            <div>
              <div style={{ fontWeight: "bold" }}>
                {data?.doctor?.firstName + " " + data?.doctor?.lastName}
              </div>
              <div style={{ fontSize: "0.8rem", color: "#888" }}>
                {data?.doctor?.designation}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "App Date",
      key: 22,
      width: 150,
      render: function (data) {
        return (
          <div>
            {moment(data?.scheduleDate).format("MMM D, YYYY")}
            <div style={{ color: "#1890ff" }}>{data?.scheduleTime}</div>
          </div>
        );
      },
    },
    {
      title: "Booking Date",
      key: 23,
      width: 120,
      render: function (data) {
        return <div>{moment(data?.createdAt).format("MMM D, YYYY")}</div>;
      },
    },
    {
      title: "Status",
      key: 24,
      width: 100,
      render: function (data) {
        return <Tag color="#f50">{data?.status}</Tag>;
      },
    },
    {
      title: "Action",
      key: 25,
      width: 100,
      render: function (data) {
        return (
          <Link to={`/dashboard/appointments/${data.id}`}>
            <Button type="primary" icon={<FaRegEye />}>
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <Card>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <FaCalendarAlt /> Appointments
            </span>
          }
          key="1"
        >
          <CustomTable
            loading={pIsLoading}
            columns={appointmentColumns}
            dataSource={data}
            showPagination={true}
            pageSize={10}
            showSizeChanger={true}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FaUserMd /> Prescriptions
            </span>
          }
          key="2"
        >
          <CustomTable
            loading={prescriptionIsLoading}
            columns={prescriptionColumns}
            dataSource={prescriptionData}
            showPagination={true}
            pageSize={10}
            showSizeChanger={true}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FaFileInvoiceDollar /> Billing
            </span>
          }
          key="3"
        >
          <CustomTable
            loading={InvoicesIsLoading}
            columns={InvoiceColumns}
            dataSource={invoices}
            showPagination={true}
            pageSize={10}
            showSizeChanger={true}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default PatientDashboard;
