import { baseApi } from "./baseApi"
const ADMIN_URL = '/admin'

export const adminApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAdmin: build.query({
            query: (id) => ({
                url: `${ADMIN_URL}/${id}`,
                method: 'GET',
            })
        }),
        updateAdmin: build.mutation({
            query: (data) => ({
              url: `${ADMIN_URL}/`,
              method: "PATCH",
              data: data,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
          }),
    })
})

export const { useGetAdminQuery , useUpdateAdminMutation  } = adminApi