import { authKey } from '../constant/storageKey';
import { decodeToken } from '../utils/jwt';
import { getFromLocalStorage } from '../utils/local-storage';

export const getUserInfo = () => {
  const authToken = getFromLocalStorage(authKey);
  if (authToken) {
    try {
      const decodedToken = decodeToken(authToken);
      if (decodedToken) {
        return decodedToken;
      } else {
        console.error("Invalid token detected");
        loggedOut(); // Clear the invalid token
        return null;
      }
    } catch (error) {
      console.error("Error in getUserInfo:", error);
      loggedOut(); // Clear the invalid token
      return null;
    }
  } else {
    return null;
  }
};
export const isLoggedIn = () => {
    const authToken = getFromLocalStorage(authKey);
    return !!authToken;
}
export const loggedOut = () => {
    return localStorage.removeItem(authKey)
}