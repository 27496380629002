import React, { useEffect, useState } from 'react'
import img from '../../../../images/avatar.jpg';
import { FaEye, FaCheck, FaTimes, FaBriefcaseMedical, FaCalendarCheck, FaCalendarDay } from "react-icons/fa";
import { useGetDoctorAppointmentsQuery, useUpdateAppointmentMutation } from '../../../../redux/api/appointmentApi';
import moment from 'moment';
import { Button, Tag, message, Card, Avatar, Tooltip } from 'antd';
import CustomTable from '../../../UI/component/CustomTable';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const DashboardPage = () => {
    const [sortBy, setSortBy] = useState("upcoming");
    const { data, refetch, isLoading } = useGetDoctorAppointmentsQuery({ sortBy });
    const [updateAppointment, { isError, isSuccess, error }] = useUpdateAppointmentMutation();

    const handleOnselect = (value) => {
        setSortBy(value === '1' ? 'upcoming' : 'today');
        refetch();
    }

    const updatedAppointmentStatus = (data, type) => {
        const changeObj = { status: type };
        if (data.id) {
            updateAppointment({ id: data.id, data: changeObj });
        }
    }

    useEffect(() => {
        if (isSuccess) {
            message.success("Successfully Updated Appointment");
        }
        if (isError) {
            message.error(error?.data?.message);
        }
    }, [isSuccess, isError, error]);

    const columns = [
        {
            title: 'Patient Name',
            key: '1',
            width: 200,
            render: function (data) {
                const fullName = `${data?.patient?.firstName ?? ''} ${data?.patient?.lastName ?? ''}`;
                const patientName = fullName.trim() || "Unknown Patient";
                const imgdata = data?.patient?.img ? data?.patient?.img : img;
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={imgdata} size={40} style={{ marginRight: 10 }} />
                        <div>
                            <div style={{ fontWeight: 'bold' }}>{patientName}</div>
                            <div style={{ fontSize: '0.8rem', color: '#888' }}>{data?.patient?.designation}</div>
                        </div>
                    </div>
                );
            }
        },
        {
            title: 'App Date',
            key: '2',
            width: 150,
            render: function (data) {
                return (
                    <div>
                        {moment(data?.scheduleDate).format("MMM D, YYYY")}
                        <div style={{ color: '#1890ff' }}>{data?.scheduleTime}</div>
                    </div>
                );
            }
        },
        {
            title: 'Status',
            key: '4',
            width: 100,
            render: function (data) {
                return (
                    <Tag color="#87d068" className='text-uppercase'>{data?.status}</Tag>
                );
            }
        },
        {
            title: 'Action',
            key: '5',
            width: 200,
            render: function (data) {
                return (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {data.prescriptionStatus === 'notIssued' ? (
                            <Link to={`/dashboard/appointment/treatment/${data?.id}`}>
                                <Tooltip title="Treatment">
                                    <Button type="primary" icon={<FaBriefcaseMedical />} />
                                </Tooltip>
                            </Link>
                        ) : (
                            <Link to={`/dashboard/prescription/${data?.prescription[0]?.id}`}>
                                <Tooltip title="View Prescription">
                                    <Button type="primary" icon={<FaEye />} />
                                </Tooltip>
                            </Link>
                        )}
                        {data?.status === 'pending' && (
                            <>
                                <Tooltip title="Accept">
                                    <Button type="primary" icon={<FaCheck />} onClick={() => updatedAppointmentStatus(data, 'accept')} />
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <Button type="primary" danger icon={<FaTimes />} onClick={() => updatedAppointmentStatus(data, 'cancel')} />
                                </Tooltip>
                            </>
                        )}
                    </div>
                );
            }
        },
    ];

    return (
        <Card>
            <Tabs defaultActiveKey="1" onChange={handleOnselect}>
                <TabPane tab={<span><FaCalendarCheck /> Upcoming</span>} key="1">
                    <CustomTable
                        loading={isLoading}
                        columns={columns}
                        dataSource={data}
                        showPagination={true}
                        pageSize={10}
                        showSizeChanger={true}
                    />
                </TabPane>
                <TabPane tab={<span><FaCalendarDay /> Today</span>} key="2">
                    <CustomTable
                        loading={isLoading}
                        columns={columns}
                        dataSource={data}
                        showPagination={true}
                        pageSize={10}
                        showSizeChanger={true}
                    />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default DashboardPage;