import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";

const PAYMENT_URL = "/payment";

export const appointmentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllPayments: build.query({
      query: (hospitalId) => ({
        url: `${PAYMENT_URL}/${hospitalId}`,
        method: "GET"
      }),
    }),
  }),
});

export const {
  useGetAllPaymentsQuery,
} = appointmentApi;
