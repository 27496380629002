import React, { useState, useEffect } from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'
import './Reviews.css';

const AdminReviews = () => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        // In a real application, this would be an API call
        // For this example, we'll use mock data
        const mockReviews = [
            {
                id: 1,
                patientName: "Charlene Reed",
                doctorName: "Dr. Ruby Perrin",
                rating: 4,
                description: "Good service, highly recommended!",
                date: "3 Nov 2019",
                time: "11.00 AM"
            },
            {
                id: 2,
                patientName: "Travis Trimble",
                doctorName: "Dr. Darren Elder",
                rating: 5,
                description: "Excellent treatment, very satisfied!",
                date: "4 Nov 2019",
                time: "10.00 AM"
            },
            // Add more mock reviews as needed
        ];

        setReviews(mockReviews);
    };

    const renderStars = (rating) => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <i key={i} className={`fe fe-star${i <= rating ? ' text-warning' : '-o text-secondary'}`}></i>
            );
        }
        return stars;
    };

    return (
        <>
            <AdminLayout>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="datatable table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Patient Name</th>
                                                <th>Doctor Name</th>
                                                <th>Ratings</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reviews.map((review) => (
                                                <tr key={review.id}>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="profile.html" className="avatar avatar-sm mr-2">
                                                                <img className="avatar-img rounded-circle" src="https://github.com/shadcn.png" alt="Patient Avatar"/>
                                                            </a>
                                                            <a href="profile.html">{review.patientName}</a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="profile.html" className="avatar avatar-sm mr-2">
                                                                <img className="avatar-img rounded-circle" src="https://github.com/shadcn.png" alt="Doctor Avatar"/>
                                                            </a>
                                                            <a href="profile.html">{review.doctorName}</a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        {renderStars(review.rating)}
                                                    </td>
                                                    <td>
                                                        {review.description}
                                                    </td>
                                                    <td>{review.date} <span className="text-primary d-block">{review.time}</span></td>
                                                    <td className="text-right">
                                                        <div className="actions">
                                                            <a className="btn btn-sm bg-danger text-white" data-toggle="modal" href="#delete_modal">
                                                                <i className="fe fe-trash"></i> Delete
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </>
    )
}

export default AdminReviews;