import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateHospitalMutation } from "../../../redux/api/hospitalApi";
import { message, DatePicker } from "antd";
import moment from "moment";
import ImageUpload from "../../UI/form/ImageUpload";
import "./AddHospital.css";
import AdminLayout from "../AdminLayout/AdminLayout";
import { getUserIdFromToken } from "../../../utils/local-storage";

const AddHospital = () => {
  const { register, handleSubmit } = useForm({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [URL, setURL] = useState("");
  const [createHospital, { isLoading, error }] = useCreateHospitalMutation();

  const logFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  };

  const onChange = (date, dateString) => {
    setDate(moment(dateString).format());
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (date) {
      data.ESTD = date;
    }
    const userId = getUserIdFromToken();
    if (userId) {
      data.adminId = userId;
    }
    console.log("userId", userId);
    formData.append("data", JSON.stringify(data));

    try {
      console.log("data", data);

      logFormData(formData);

      const resp = await createHospital(formData);
      //   console.log("after");

      //   logFormData(formData);
      console.log("resp" , resp);
      if (resp.data) {
        message.success("Hospital added successfully");
        setURL(resp.data);
      }
      message.error("Failed to add hospital");
    } catch (error) {
      message.error(error?.data?.message || "Failed to add hospital");
    }
  };

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Hospital</h4>
              {URL ? (
                <>
                  {" "}
                  Hospital Added Successfully
                  <p>URL: {URL}</p>{" "}
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="change-avatar d-flex gap-2 align-items-center">
                        <div className="hospital-img">
                          <img
                            src={
                              selectedImage || "https://via.placeholder.com/100"
                            }
                            alt="Hospital Avatar"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div>
                          <ImageUpload
                            setSelectedImage={setSelectedImage}
                            setFile={setFile}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Hospital Name <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("name", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Address <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("address", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("city", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          State <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("state", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          ZipCode <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("zipCode", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Country <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("country", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("phone", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("email", { required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Established Date</label>
                        <DatePicker
                          onChange={onChange}
                          format={"YYYY-MM-DD"}
                          style={{ width: "100%" }}
                          value={date ? moment(date) : null}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label>Description</label>
                        <textarea
                          {...register("description")}
                          className="form-control"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? "Adding..." : "Add Hospital"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AddHospital;
