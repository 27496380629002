import React, { useState, useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useGetPatientAppointmentsQuery } from "../../../redux/api/appointmentApi";
import { useGetHospitalIdFromUserIdQuery } from "../../../redux/api/hospitalApi"; // Changed to query hook
import { message } from "antd";
import { getUserIdFromToken } from "../../../utils/local-storage";

import useAuthCheck from "../../../redux/hooks/useAuthCheck";
const AdminAppointments = () => {
  const [appointments, setAppointments] = useState([]);

  // Assuming you have a function to get the user ID
  const userData = getUserIdFromToken();
  const userId = userData;

  console.log("User ID:", userId);

  // Fetch the hospital ID using the user ID
  const { data: hospitalData, isLoading: hospitalLoading, error: hospitalError } =
  useGetHospitalIdFromUserIdQuery(userId);

  console.log("Hospital Data:", hospitalData);

  // Fetch the patient appointments using the hospital ID
  const { data: patientAppointments, isLoading: appointmentsLoading, error: appointmentsError } =
    useGetPatientAppointmentsQuery(hospitalData?.id, {
      skip: !hospitalData, 
    });
    console.log("patientAppointments" , patientAppointments);
    

  useEffect(() => {
    if (patientAppointments) {
      setAppointments(patientAppointments);
    }
  }, [patientAppointments]);


  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="datatable table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Doctor Name</th>
                      <th>Speciality</th>
                      <th>Patient Name</th>
                      <th>Appointment Time</th>
                      <th>Status</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.map((appointment) => (
                      <tr key={appointment.id}>
                        <td>
                          <h2 className="table-avatar">
                            <a
                              href="profile.html"
                              className="avatar avatar-sm mr-2"
                            >
                              <img
                                className="avatar-img rounded-circle"
                                src="https://github.com/shadcn.png"
                                alt=""
                              />
                            </a>
                            <a href="profile.html">{appointment.doctorName}</a>
                          </h2>
                        </td>
                        <td>{appointment.speciality}</td>
                        <td>
                          <h2 className="table-avatar">
                            <a
                              href="profile.html"
                              className="avatar avatar-sm mr-2"
                            >
                              <img
                                className="avatar-img rounded-circle"
                                src="https://github.com/shadcn.png"
                                alt=""
                              />
                            </a>
                            <a href="profile.html">{appointment.patientName}</a>
                          </h2>
                        </td>
                        <td>
                          {appointment.appointmentDate}
                          <span className="text-primary d-block">
                            {appointment.appointmentTime}
                          </span>
                        </td>
                        <td>
                          <div className="status-toggle">
                            <input
                              type="checkbox"
                              id={`status_${appointment.id}`}
                              className="check"
                              checked={appointment.status}
                              readOnly
                            />
                            <label
                              htmlFor={`status_${appointment.id}`}
                              className="checktoggle"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="text-right">
                          ${appointment.amount.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAppointments;
